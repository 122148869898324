import { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import { scroller } from 'react-scroll';
import { motion } from 'framer-motion';
import logo from '../resources/icon_high_res.png';

// Define types
interface Resource {
  title: string;
  link: string;
}

interface MethodologySegment {
  id: string;
  title: string;
  summary: string;
  content: string;
  color: string;
  resources: Resource[];
}

interface UserProgress {
  completedAssessments: number;
  badges: string[];
  lastCompletionDate: Date;
}

// Add this interface before the methodologySegments
interface MonthlyLearning {
  topic: string;
  description?: string;
}

// Add this function before the Finished component
const getCurrentMonthLearning = (): MonthlyLearning => {
  const monthlyTopics: MonthlyLearning[] = [
    { topic: "Meaningful Work", description: "Finding purpose in your daily tasks" },
    { topic: "Clinical Spirituality", description: "Connecting with our higher purpose" },
    { topic: "S.P.A.R.C Relationships", description: "Building stronger team connections" },
    { topic: "Hobbies & Interests", description: "Balancing work and personal life" },
    { topic: "Physical Health", description: "Taking care of your wellbeing" },
    { topic: "Meaningful Work", description: "Aligning with your values" },
    { topic: "Clinical Spirituality", description: "Mindfulness in practice" },
    { topic: "S.P.A.R.C Relationships", description: "Effective communication" },
    { topic: "Hobbies & Interests", description: "Personal growth outside work" },
    { topic: "Physical Health", description: "Workplace wellness habits" },
    { topic: "Meaningful Work", description: "Goal setting and achievement" },
    { topic: "Clinical Spirituality", description: "Year-end reflection and purpose" }
  ];
  
  const currentMonth = new Date().getMonth();
  return monthlyTopics[currentMonth];
};

// Define our methodology segments
const methodologySegments: MethodologySegment[] = [
  {
    id: 'meaningful-work',
    title: 'Meaningful\nWork',
    summary: 'Understanding your role, responsibilities, and how success is measured',
    content: 'Meaningful work comes from having a clear understanding of your role, responsibilities, and how success is measured. When you understand your responsibilities and how success is measured, you can better align your strengths with your work and find greater engagement and resilience. This involves seeking role clarity, embracing feedback, and ensuring your position allows you to thrive.\n\n<span class="text-orange-500 font-medium">Do you have clarity about your role and how your success is measured?</span>',
    color: 'bg-blue-800',
    resources: [
      { title: 'Career Development Resources', link: 'https://learn.strataintel.com/domains#meaningful-work' },
    ]
  },
  {
    id: 'clinical-spirituality',
    title: 'Clinical\nSpirituality',
    summary: 'Finding purpose beyond your daily tasks',
    content: 'Clinical spirituality emerges when you connect your work to something larger than yourself. It flourishes when you align your daily activities with the organization`s mission and values, creating a sense of purpose that fuels trust and sustainable performance. This connection helps you see how your individual contributions serve a greater purpose.\n\n<span class="text-orange-500 font-medium">How do you connect your daily work to something meaningful beyond yourself?</span>',
    color: 'bg-blue-600',
    resources: [
      { title: 'Mental Health Resources', link: 'https://learn.strataintel.com/domains#spirituality' },

    ]
  },
  {
    id: 'relationships',
    title: 'S.P.A.R.C\nRelationships',
    summary: 'Building connections that drive growth and success',
    content: 'Strong workplace relationships thrive when you engage in meaningful work and connect with a higher purpose. SPARC relationships are Stress-inducing (creating positive pressure), Performance-based, Accountability-centric, Results-oriented, and Committed to continuous improvement. These connections create healthy challenges that elevate performance while providing the support needed for well-being. By fostering these growth-focused relationships, you build a network that helps you excel through both support and positive challenge.\n\n<span class="text-orange-500 font-medium">Are you building relationships that both challenge and support your growth?</span>',
    color: 'bg-blue-500',
    resources: [
      { title: 'Team Building Resources', link: 'https://learn.strataintel.com/domains#relationships-sparc' },
    ]
  },
  {
    id: 'hobbies-interests',
    title: 'Hobbies &\nInterests',
    summary: 'Maintaining passions outside of work',
    content: 'Your hobbies, interests, and activities outside of work are vital to your overall resilience and creativity. By actively pursuing hobbies and personal interests, you create natural stress outlets and enhance your ability to handle workplace challenges. Making time for these activities helps maintain work-life balance and strengthens your overall well-being.\n\n<span class="text-orange-500 font-medium">What activities outside of work help you recharge and maintain resilience?</span>',
    color: 'bg-gray-400',
    resources: [
      { title: 'Work-Life Balance Guide', link: 'https://learn.strataintel.com/domains#hobbies-and-interests' },
    ]
  },
  {
    id: 'physical-health',
    title: 'Physical\nHealth',
    summary: 'Taking care of your physical health needs',
    content: 'Your physical health needs are the foundation for sustained high performance. By prioritizing adequate sleep, regular exercise, and hyrdration & nutrition, you build the stamina needed for long-term success. Setting healthy boundaries around work hours and maintaining consistent wellness habits helps you manage stress and maintain peak performance.\n\n<span class="text-orange-500 font-medium">How are you prioritizing your sleep, diet, and exercise to sustain high performance?</span>',
    color: 'bg-gray-500',
    resources: [
      { title: 'Wellness Resources', link: 'https://learn.strataintel.com/domains#physical-health' },
    ]
  },
];

export default function Finished(): JSX.Element {
  const currentMonthLearning = getCurrentMonthLearning();
  const [selectedSegment, setSelectedSegment] = useState<MethodologySegment>(() => {
    const defaultSegment = methodologySegments.find(segment => 
      segment.title.replace('\n', ' ').trim() === currentMonthLearning.topic.trim()
    );
    if (!defaultSegment) {
      throw new Error('No matching segment found for current month');
    }
    return defaultSegment; 
  });
  const [userProgress, setUserProgress] = useState<UserProgress>({
    completedAssessments: 1,
    badges: ['First Assessment', 'Wellness Champion', 'Early Adopter'],
    lastCompletionDate: new Date()
  });
  
  const currentMonth = new Date().getMonth();

  useEffect(() => {
    scroller.scrollTo("startDiv", {});
  }, []);

  const handleSegmentClick = (segment: MethodologySegment) => {
    console.log('Segment clicked:', segment.title);
    setSelectedSegment(segment);
  };

  window.onbeforeunload = null;

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div id="startDiv" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
        {/* Get Support Button */}
        {/* <div className="absolute top-4 right-4 sm:top-8 sm:right-8">
          <motion.a
            href="https://988lifeline.org/"
            target="_blank"
            className="inline-flex items-center gap-2 px-4 py-2 bg-red-50 hover:bg-red-100 
                     text-red-600 font-medium rounded-full shadow-sm hover:shadow-md 
                     transition-all group"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <svg 
              className="w-5 h-5 group-hover:scale-110 transition-transform" 
              fill="currentColor" 
              viewBox="0 0 24 24"
            >
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
            </svg>
            <span>Get Support</span>
          </motion.a>
        </div> */}

        {/* Thank You Section */}
        <motion.div 
          className="max-w-5xl mx-auto text-center mb-8 sm:mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <h1 className="text-4xl sm:text-4xl font-bold text-gray-900 mb-4 sm:mb-6">
            Thank You for Participating and Strengthening Our Team!
          </h1>
          <p className="text-lg sm:text-xl text-gray-600 mb-8">
            Your responses are completely anonymous and help create a more supportive and resilient workplace. Explore this month's team core strength focus area and additional resources below.
          </p>

          {/* Monthly Focus moved here */}
          <div className="bg-blue-50 rounded-xl p-6 max-w-2xl mx-auto">
            <h3 className="text-lg text-blue-900">
              <span className="text-blue-700">
                {new Date().toLocaleDateString('en-US', { month: 'long', year: 'numeric' })} Focus Area:
              </span>{" "}
              <motion.span
                className="font-semibold"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                key={currentMonthLearning.topic}
              >
                {currentMonthLearning.topic}
              </motion.span>
            </h3>
          </div>
        </motion.div>

        {/* Main Content Area */}
        <div className="grid lg:grid-cols-2 gap-8 lg:max-h-[700px] mb-28">
          {/* Deep Dive Section */}
          <motion.div 
            className="h-[500px] lg:h-full overflow-auto flex flex-col order-2 lg:order-1"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4 }}
          >
            {/* Domain Content */}
            <div className="bg-white rounded-2xl  p-6 sm:p-8 w-full flex-1 border-2 border-gray-200">
              <motion.div
                key={selectedSegment?.id || 'empty'}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-4 sm:mb-6 flex items-center gap-3">
                  <span>{selectedSegment.title}</span>
                </h2>
                
                <div className="min-h-[300px]">
                  <div className="prose max-w-none mb-6">
                    <p className="text-lg text-gray-600">{selectedSegment.summary}</p>
                  </div>
                  
                  <div className="border-t border-gray-100 pt-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-3">
                      Why This Matters
                    </h3>
                    <p className="text-gray-600 mb-6" dangerouslySetInnerHTML={{ __html: selectedSegment.content }}>
                    </p>
                    
                    <div className="flex items-center justify-between pt-4 border-t border-gray-100">
                      <a 
                        href={selectedSegment.resources[0].link}
                        target="_blank"
                        className="inline-flex items-center text-blue-600 hover:text-blue-800 transition-colors group"
                      >
                        <span className="font-medium">Learn More</span>
                        <svg 
                          className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" 
                          fill="none" 
                          viewBox="0 0 24 24" 
                          stroke="currentColor"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M13 7l5 5m0 0l-5 5m5-5H6" 
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>

          <div className="order-1 lg:order-2">
          {/* Wellness Areas Circle */}
          <motion.div 
            className="relative h-[400px] sm:h-[500px] lg:h-full flex items-center justify-center order-1 lg:order-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <div className="w-full max-w-[300px] sm:w-[80%] h-[80%] relative">
              {/* Center Logo */}
              <motion.img 
                src={logo}
                alt="Company Logo"
                className="absolute inset-0 m-auto w-16 sm:w-24 h-16 sm:h-24 object-contain z-20"
                initial={{ opacity: 0 }}
                animate={{ 
                  opacity: 1,
                  scale: selectedSegment ? 1.1 : 1,
                }}
                transition={{ duration: 0.3 }}
              />

              {/* Methodology Segments */}
              {methodologySegments.map((segment, index) => {
                const angle = (index * 360) / methodologySegments.length;
                const radius = window.innerWidth < 640 ? 120 : 180;
                const x = Math.cos((angle - 90) * (Math.PI / 180)) * radius;
                const y = Math.sin((angle - 90) * (Math.PI / 180)) * radius;
                
                return (
                  <motion.div
                    key={segment.id}
                    className="absolute top-1/2 left-1/2 w-28 sm:w-40 select-none"
                    style={{
                      transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))`,
                    }}
                    onClick={() => handleSegmentClick(segment)}
                  >
                    <div className={`
                      ${segment.color} p-3 sm:p-4 rounded-2xl shadow-lg
                      ${selectedSegment?.id === segment.id ? 'ring-4 ring-orange-400 scale-110' : ''}
                      transition-all duration-300 cursor-pointer
                      ${!selectedSegment && 'hover:ring-2 hover:ring-blue-200'}
                    `}>
                      <h3 className="text-base sm:text-lg font-semibold text-white text-center select-none">
                        {segment.title}
                      </h3>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
            <div className="text-center text-sm text-gray-600 font-medium italic -m-2">
              Select any domain on the wheel to learn more
            </div>
          </div>
        </div>

        {/* Resources Section */}
        {/* <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          <div className="max-w-7xl mx-auto">
            <div className="border border-gray-200 bg-gray-50/50 rounded-xl p-6 sm:p-8">
              <div className="mb-6">
                <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-3">
                  Company Specific Resources
                </h2>
                <p className="text-base sm:text-lg text-gray-600">
                  Your dedicated support network and company-specific resources
                </p>
              </div>

              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            
                <div className="bg-white rounded-xl p-5 border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
                  <div className="flex items-start gap-4">
                    <div className="p-2 bg-blue-50 rounded-lg">
                      <svg className="w-6 h-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900 mb-1">HR Support</h3>
                      <p className="text-sm text-gray-600 mb-3">Contact your HR team for support</p>
                      <a href="mailto:hr@company.com" className="text-blue-600 hover:text-blue-700 text-sm font-medium">
                        hr@company.com
                      </a>
                    </div>
                  </div>
                </div>

             
                <div className="bg-white rounded-xl p-5 border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
                  <div className="flex items-start gap-4">
                    <div className="p-2 bg-green-50 rounded-lg">
                      <svg className="w-6 h-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                      </svg>
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900 mb-1">Mental Health Support</h3>
                      <p className="text-sm text-gray-600 mb-3">24/7 confidential counseling</p>
                      <a href="tel:1-800-555-0000" className="text-blue-600 hover:text-blue-700 text-sm font-medium">
                        1-800-555-0000
                      </a>
                    </div>
                  </div>
                </div>


                <div className="bg-white rounded-xl p-5 border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
                  <div className="flex items-start gap-4">
                    <div className="p-2 bg-purple-50 rounded-lg">
                      <svg className="w-6 h-6 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                      </svg>
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900 mb-1">Benefits Portal</h3>
                      <p className="text-sm text-gray-600 mb-3">Access your benefits information</p>
                      <a href="https://benefits.company.com" className="text-blue-600 hover:text-blue-700 text-sm font-medium">
                        View Benefits
                      </a>
                    </div>
                  </div>
                </div>


                <div className="bg-white rounded-xl p-5 border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
                  <div className="flex items-start gap-4">
                    <div className="p-2 bg-yellow-50 rounded-lg">
                      <svg className="w-6 h-6 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900 mb-1">Wellness Program</h3>
                      <p className="text-sm text-gray-600 mb-3">Access wellness resources</p>
                      <a href="/wellness" className="text-blue-600 hover:text-blue-700 text-sm font-medium">
                        Explore Programs
                      </a>
                    </div>
                  </div>
                </div>

          
                <div className="bg-white rounded-xl p-5 border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
                  <div className="flex items-start gap-4">
                    <div className="p-2 bg-red-50 rounded-lg">
                      <svg className="w-6 h-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900 mb-1">Crisis Support</h3>
                      <p className="text-sm text-gray-600 mb-3">24/7 emergency assistance</p>
                      <a href="tel:988" className="text-blue-600 hover:text-blue-700 text-sm font-medium">
                        988
                      </a>
                    </div>
                  </div>
                </div>


                <div className="bg-white rounded-xl p-5 border border-gray-100 shadow-sm hover:shadow-md transition-shadow">
                  <div className="flex items-start gap-4">
                    <div className="p-2 bg-indigo-50 rounded-lg">
                      <svg className="w-6 h-6 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                      </svg>
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900 mb-1">EAP Resources</h3>
                      <p className="text-sm text-gray-600 mb-3">Additional support services</p>
                      <a href="/eap" className="text-blue-600 hover:text-blue-700 text-sm font-medium">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div> */}
        
      </div>

      <Footer />
    </div>
  );
}
