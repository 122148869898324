import React, { createContext, useContext, useState, ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

const SUPPORTED_LANGUAGE_CODES = ["en", "es"] as const;
type LanguageCode = (typeof SUPPORTED_LANGUAGE_CODES)[number];

// we could pull this from the database, but I'm hardcoding it here for efficiency
// make sure to add the language translation here when adding new language
const LANGUAGE_ID_TO_CODE_TRANSLATIONS: Record<number, LanguageCode> = {
  1: "en",
  2: "es",
};

interface LanguageContextType {
  language: LanguageCode | null;
  setLanguage: (lang: string | number) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

interface LanguageProviderProps {
  children: ReactNode;
}

function isValidLanguageCode(code: string): code is LanguageCode {
  return (SUPPORTED_LANGUAGE_CODES as readonly string[]).includes(code);
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const queryLanguage = searchParams.get("lang");
  let defaultLanguage: LanguageCode | null = null;
  if (queryLanguage && isValidLanguageCode(queryLanguage)) {
    defaultLanguage = queryLanguage as LanguageCode;
  }

  const [languageState, setLanguageState] = useState<LanguageCode | null>(defaultLanguage);

  const setLanguage = (lang: string | number) => {
    let languageCode = null;
    if (typeof lang === "number") {
      languageCode = LANGUAGE_ID_TO_CODE_TRANSLATIONS[lang] ?? "en";
    } else if (typeof lang === "string" && isValidLanguageCode(lang)) {
      languageCode = lang;
    }

    setLanguageState(languageCode);
  };

  return <LanguageContext.Provider value={{ language: languageState, setLanguage }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};

export const useContent = () => {
  const { language } = useLanguage();
  function getContent<K extends keyof Content>(key: K) {
    return content[key][language ?? "en"];
  }
  return getContent;
};


const content = {
  title: {
    en: "Group Well-Being Assesment",
    es: "Evaluación del bienestar grupal",
  },
  languageSelect: {
    en: "Select your language",
    es: "Seleccione su idioma"
  },
  enterKey: {
    en: "Enter assessment key",
    es: "Ingrese la clave de evaluación",
  },
  enter: {
    en: "Enter",
    es: "Ingresar",
  },
  acceptDisclaimer: {
    en: "I acknowledge having read this disclaimer and agree to Strata's use of my responses as described in this disclaimer.",
    es: "Reconozco haber leído este descargo de responsabilidad y acepto el uso de mis respuestas por parte de Strata según lo descrito en este descargo de responsabilidad",
  },
  continue: {
    en: "Continue",
    es: "Continuar",
  },
  yearsExperience: {
    en: "How many years have you been in your current role?",
    es: "¿Cuántos años lleva en su puesto actual?",
  },
  age: {
    en: "What is your age?",
    es: "¿Cuál es su edad?",
  },
  gender: {
    en: "What is your gender?",
    es: "¿Cuál es su género?",
  },
  navigateAway: {
    en: "Are you sure you want to navigate away? Your responses will not be saved.",
    es: "¿Está seguro de que quiere salir de esta página? Sus respuestas no se guardarán.",
  },
  question: {
    en: "Question",
    es: "Pregunta",
  },
  of: {
    en: "of",
    es: "de",
  },
  finish: {
    en: "Finish",
    es: "Finalizar",
  },
} as const;

type Content = typeof content;
