import { useEffect, useState } from "react";
import { useContext } from "react";
import SurveyContext from "../context/surveyContext";
import Icon from "../resources/main-icon.svg";
import LogoWords from "../resources/main-no-icon.svg";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useContent, useLanguage } from "../context/languageContext";

export default function Home() {
  const [surveyKey, setSurveyKey] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const { language, setLanguage } = useLanguage();
  const content = useContent();

  const { contextState, contextDispatch } = useContext(SurveyContext);

  useEffect(() => {
    const queryParamKey = new URLSearchParams(window.location.search).get("key");
    if (queryParamKey) {
      checkSurveyKey(queryParamKey);
    }
  }, []);

  async function checkSurveyKey(surveyKey: string) {
    //if user doesn't already have a valid key, call endpoint
    if (!contextState.user.survey_key) {
      setSubmitting(true);
      if (surveyKey) {
        try {
          //check if survey key is valid
          const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/user/validate`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Content-Language": language ?? "en",
              Authorization: surveyKey,
            },
          });
          const body = await response.json();

          if (response.status === 401) {
            setError(body.errors[0]);
            setSubmitting(false);
            return;
          }

          if (response.status === 200) {
            const languageId = body.language_id ? parseInt(body.language_id) : null;
            if(languageId && languageId > 1) { // user has language other than English
              setLanguage(languageId);
            }
            contextDispatch({
              type: "updateUser",
              payload: { survey_key: surveyKey, languge_id: body.language_id },
            });
            contextDispatch({
              type: "updateSurveyId",
              payload: body.survey_id
            })
            navigate("/disclaimer");
          }
        } catch (err) {
          console.error(err);
          setError("An error has occured, please try again later");
        }
      } else {
        setError("Please enter an assessment key");
      }
      setSubmitting(false);
    }
  }

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
        <div className="text-center">
          <div className="relative mx-auto w-full lg:max-w-md md:block">
            <img src={Icon} alt="Icon" className="h-16 mx-auto" />
            <img src={LogoWords} alt="logo" className="h-12 mx-auto mt-4" />
            <h1 className="text-center text-3xl text-strata-blue font-bold mt-1">{content("title")}</h1>
            <div className="mt-3 sm:flex">
              <input
                type="text"
                name="key"
                aria-invalid="true"
                aria-describedby="error"
                id="key"
                maxLength={10}
                className="block w-full sm:w-auto py-3 text-base rounded-sm placeholder-zinc-500 shadow-sm focus:ring-strata-blue focus:border-strata-blue sm:flex-1 border-zinc-300 sm:mr-1 mr-0"
                placeholder={content("enterKey")}
                onChange={(e: any) => setSurveyKey(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && checkSurveyKey(surveyKey)}
              />
              <Button text={content("enter")} type="submit" size="lg" submitting={submitting} onClick={() => checkSurveyKey(surveyKey)} />
            </div>
            <div>
              {error && (
                <div className="mt-3 text-sm flex items-center">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  <span id="error" className="text-sm text-red-500 ml-2">
                    {error}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
